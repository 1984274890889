/* 
 * Make Request - internal plug-in for setting up consistent UI flows with ajax calls. There are three types of ajax calls used: form submit, button click, document ready.

 [ more info to come on use case scenarios ]

*/

(function($){

	$.fn.makeRequest = function(options){
    
		var settings = $.extend({
			// Defaults
			container : $('#container'),
			form : this,
			button : $('#' + this.attr('id') + '-btn'),
			selector : '',
			loading : $('#' + this.attr('id') + '-loading'),
			msg : $('#' + this.attr('id') + '-msg'),
			event : 'load',
			isBtnDeactive : false,
			hideBtn : true,
			url : this.attr('data-src'),
			dataType : 'json',
			confirmTpl : false,
			confirmAcceptBtn : '.green-btn',
			confirmCancelBtn : '.red-btn',
			confirmMsg : 'Are you sure?',
			validate : 'false', 
			reqAlways : function (results) {
				if (debug) {
					console.log('reqAlways results: ', results);
				}
			},
			reqBeforeSend : function (button,loading,msg,hideBtn) {
				if (loading.length > 1) {
					loading = button.siblings().filter(loading);
				}
				$.fn.makeRequest.toggleBtn(button,false,'','');
				$.fn.makeRequest.toggleLoading(loading,true);
				$.fn.makeRequest.toggleMsg(msg,false,'','success error','');
			},
			reqData : function (form,button,loading,msg) {
				var data = '';
				return data;
			},
			reqDone : function (results) {
				if (debug) {
					console.log('reqDone results: ', results);
				}
			},
			reqError : function (results,$form,$button,$loading,$msg) {
				if (debug) {
					console.log('reqError results: ', results);
				}
			},
			reqFail : function (req,error,$button,$loading,$msg) {
				if (error === 'error') {
					error = req.statusText;
				}
				if ($loading.length > 1) {
					$loading = $button.siblings().filter($loading);
				}	
				$.fn.makeRequest.toggleBtn($button,true,'','');
				$.fn.makeRequest.toggleLoading($loading,false);
				$.fn.makeRequest.toggleMsg($msg,true,'error','','An error has occurred. ' + error);
			},
			reqSuccess : function (results,$form,$button,$loading,$msg) {
				if (debug) {
					console.log('reqSuccess results: ', results);
				}
			},
			reqValidation : function (isBtnDeactive,hideBtn,$button,$loading,$msg,$form,url,dataType) {
				var validator = $form.validate({
					submitHandler: function($form) { 
						//passed validation
						if ($button.hasClass('deactive')) {
							$.fn.makeRequest.toggleMsg($msg,true,'error','success','There are no changes to save at this time.');
						} else {
							if (confirm) {
								confirmThis(isBtnDeactive,hideBtn,$button,$loading,$msg,$form,url,dataType,$($form).serialize(),validator);
							} else {
								ajax(isBtnDeactive,hideBtn,$button,$loading,$msg,$form,url,dataType,$($form).serialize(),validator);
							}
						}
					}
				});
				$container.on('resetForm.makeRequest.mrreset', function () {
					$form.find('.form-field').each(function () {
						$(this).find('.form-field-msg').remove();
						$(this).find('.invalid').removeClass('invalid');
					});
					validator.resetForm();
				});
			},
			reqConfirm : function(button){},
			reqCallback: function(){},
			debug : false
		}, options);

		var $container = settings.container,
			$form = settings.form,
			form = settings.form.selector,
			$button = settings.button,
			button = settings.button.selector,
			selector = settings.selector,
			$loading = settings.loading,
			$msg = settings.msg,
			event = settings.event,
			isBtnDeactive = settings.isBtnDeactive,
			hideBtn = settings.hideBtn,
			url = settings.url,
			dataType = settings.dataType,
			confirmTpl = settings.confirmTpl,
			confirmAcceptBtn = settings.confirmAcceptBtn,
			confirmCancelBtn = settings.confirmCancelBtn,
			validate = settings.validate,
			reqAlways = settings.reqAlways,
			reqBeforeSend = settings.reqBeforeSend,
			reqData = settings.reqData,
			reqDone = settings.reqDone,
			reqError = settings.reqError,
			reqFail = settings.reqFail,
			reqSuccess = settings.reqSuccess,
			reqValidation = settings.reqValidation,
			reqConfirm = settings.reqConfirm,
			reqCallback = settings.reqCallback,
			debug = settings.debug;

		if (event == 'click' && $button.length == 0) {
			$button = settings.form;
			button = settings.form.selector;
		}
		// if selector is specified, override previous settings
		if (selector.length) {
			button = selector;
			form = selector;
		}

		// verify that the confirmTpl is present on the page
		var confirm = confirmTpl.length ? true : false;

		switch (event) {
			case 'submit':
				$.fn.makeRequest.initializeBtn(isBtnDeactive,$button,$msg);

				if (url == '' || url == undefined) {
					url = $form.attr('action');
					if (debug) {console.log('url is ',url);}
				}
				
				if (validate == true) {
					reqValidation.call($form,isBtnDeactive,hideBtn,$button,$loading,$msg,$form,url,dataType);
				} else {
					$container.on('submit.makeRequest.mrsubmit', form, function (e) {
						e.preventDefault();

						if (debug) {
							console.log('-----> Submit');
						}
						if ($button.hasClass('deactive')) {
						 	$.fn.makeRequest.toggleMsg($msg,true,'error','success','There are no changes to save at this time.');
						} else {
							if (confirm) {
								confirmThis(isBtnDeactive,hideBtn,$button,$loading,$msg,$form,url,dataType,$form.serialize());
							} else {
								ajax(isBtnDeactive,hideBtn,$button,$loading,$msg,$form,url,dataType,$form.serialize());
							}
						}
					});
				}
				break;
			case 'click':
				$container.on('click.makeRequest.mrclick', button, function (e) {
					e.preventDefault();

					var $targetBtn = $button;
					if ($targetBtn.length > 1) {
						$targetBtn = $(e.target);
					}

					if (debug) {
						console.log('$targetBtn object: ', $targetBtn);
					};

					//define loading spinner if one is not defined
					if (typeof $loading != 'object') {
						$loading = $targetBtn.next();
					}

					//define url if one is not defined 
					if ($form.length > 1 || url == '' || url == undefined) {
						if ($targetBtn.is('a') && $targetBtn.attr('href') != '#' && $targetBtn.attr('href') != '') {
							url = $targetBtn.attr('href');
						}
						if ($targetBtn.is('button')) {
							url = $targetBtn.data('src');
						}
					}

					if (confirm) {
						confirmThis(isBtnDeactive,hideBtn,$targetBtn,$loading,$msg,form,url,dataType,reqData.call($targetBtn,$form,$targetBtn,$loading,$msg));
					} else {
						ajax(isBtnDeactive,hideBtn,$targetBtn,$loading,$msg,form,url,dataType,reqData.call($targetBtn,$form,$targetBtn,$loading,$msg));
					}
				});
				break;
			case 'load':
				ajax(isBtnDeactive,hideBtn,$button,$loading,$msg,form,url,dataType,reqData.call($form,$button,$loading,$msg));
				break
			default:
				ajax(isBtnDeactive,hideBtn,$button,$loading,$msg,form,url,dataType,reqData.call($targetBtn,$form,$button,$loading,$msg));
				break;
		}

		if (debug) {
			console.info('======> makeRequest init for: ', form);
			console.log('Settings object: ', settings);
			console.log('form is ' + form + ': ', $form);
			console.log('button is ' + button + ' (may be same as form): ', $button);
			if (selector.length) {console.log('selector is: ',selector);}
			console.log('$loading object is: ', $loading);
			console.log('$msg object is: ', $msg);
			console.log('event is ', event);
			console.log('isBtnDeactive is ', isBtnDeactive);
			console.log('hideBtn is ', hideBtn);
			console.log('url is ', url);
			console.log('dataType is ', dataType);
			console.log('confirm is ', confirm);
			if (confirm) {
				console.log('confirmTpl is ', confirmTpl);
				console.log('confirmAcceptBtn is ', confirmAcceptBtn);
				console.log('confirmCancelBtn is ', confirmCancelBtn);
			}
			console.log('validate is ', validate);
		}

		function ajax (isBtnDeactive,hideBtn,$button,$loading,$msg,$form,url,dataType,data,validator) {
			//exit if url is empty. found an empty url re-load current page in an infinite loop
			if (url == '') {
				return false;
			}
			
			$.ajax({
				url: typeof url === 'function' ? url() : url,
				type: 'POST',
				data: data,
				cache: false,
				dataType: dataType,
				beforeSend: function (xhr) {
					reqBeforeSend.call($form,$button,$loading,$msg,hideBtn,xhr);
				}
			})
			.done(function (results) {
				if (dataType == 'json' || dataType == 'jsonp') {
					if (results.status.toUpperCase() == 'SUCCESS' || results.status.toUpperCase() == 'OK') {
						reqSuccess.call($form,results,$form,$button,$loading,$msg);
						$.fn.makeRequest.initializeBtn(isBtnDeactive,$button,$msg);
						if (!hideBtn) {
							$.fn.makeRequest.toggleBtn($button,true,'','');
						}
						if (validate == true) {
							validator.resetForm();
						}
					} else {
						reqError.call($form,results,$form,$button,$loading,$msg);
						$.fn.makeRequest.toggleBtn($button,true,'','');
					}
					
					if ($loading.length > 1) {
						$loading = $button.siblings().filter($loading);
					}
					$.fn.makeRequest.toggleLoading($loading,false);

					if (results.message != null) {
						$.fn.makeRequest.toggleMsg($msg,true,results.status,'',results.message);
					}
				} else {
					reqDone.call($form,results);
				}
			})
			.fail(function (req,error) {
				reqFail.call($form,req,error,$button,$loading,$msg);
				if (debug) {
					console.log('fail req: ', req);
				}
			})
			.always(function(results) {
				reqAlways.call($form,results);
			});
		};

		function confirmThis (isBtnDeactive,hideBtn,$button,$loading,$msg,$form,url,dataType,data,validator) {
			$.magnificPopup.open({
				items: {
					src: confirmTpl.html()
				},
				callbacks: {
					open: function () {
						$(this.content).one('click', confirmAcceptBtn, function () {
							$.magnificPopup.instance.close();
							ajax(isBtnDeactive,hideBtn,$button,$loading,$msg,$form,url,dataType,data,validator);
						});
						$(this.content).one('click', confirmCancelBtn, function () {
							$.magnificPopup.instance.close();
						});
					}
				},
				mainClass: 'confirm-modal',
				type: 'inline',
				modal: true
			});
			reqConfirm.call($button);
		};

		// Main Callback
		reqCallback.call($form);

		return $form;

	};

	$.fn.makeRequest.initializeBtn = function (isBtnDeactive,$button,$msg) {
		if (isBtnDeactive) {
			if (!$button.hasClass('deactive')) {
				$.fn.makeRequest.deactivateBtn($button);
			}
			$.fn.makeRequest.activateBtn($button,$msg);
		}
	}

	$.fn.makeRequest.toggleBtn = function ($button,isVisible,btnAddClass,btnRemoveClass) {
		if (typeof $button == 'object') {
			if (isVisible) {
				btnRemoveClass += ' hidden';
			} else {
				btnAddClass += ' hidden';
			}
			//add classes when needed
			if (btnAddClass != '') {
				$button.addClass(btnAddClass);
			}
			//remove classes when needed
			if (btnRemoveClass != '') {
				$button.removeClass(btnRemoveClass);
			}
			//add ARIA status of the button
			if ($button.hasClass('deactive')) {
				$button.attr('aria-disabled',true);
			}else {
				$button.attr('aria-disabled',false);
			}
		}
	}

	$.fn.makeRequest.activateBtn = function ($button,$msg) {
		$button.closest('form').one('change', 'input,select,textarea', function (e) {
			$.fn.makeRequest.toggleBtn($button,true,'active','cancel deactive');
			$.fn.makeRequest.toggleMsg($msg,false,'','success error','');
		});
	}

	$.fn.makeRequest.deactivateBtn = function ($button) {
		$.fn.makeRequest.toggleBtn($button,true,'deactive','active');
	}

	$.fn.makeRequest.toggleLoading = function ($loading,isVisible) {
		if (typeof $loading == 'object') {
			if (isVisible) {
				$loading.removeClass('hidden');
			} else {
				$loading.addClass('hidden');
			}
		}
	}

	$.fn.makeRequest.toggleMsg = function ($msg,isVisible,msgAddClass,msgRemoveClass,content) {
		if (typeof $msg == 'object') {
			if (isVisible) {
				msgRemoveClass += ' hidden';
			} else {
				msgAddClass += ' hidden';
			}
			//update msg content
			if (content == '') {
				$msg.empty();
			} else {
				$msg.html(content);
			}
			//add classes when needed
			if (msgAddClass != '') {
				$msg.addClass(msgAddClass);
			}
			//remove classes when needed
			if (msgRemoveClass != '') {
				$msg.removeClass(msgRemoveClass).attr('role','alert');
			}
		}
	}

}(jQuery));